.mainHomeContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#recaptcha-container{
    display: none;
}

.leftPanel{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(15vw);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    margin-top: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.mainPanel{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    margin-left: calc(14.5vw);
    width: calc(84vw);
}

.mainPanelInnerContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* align-items: center;
    flex-wrap: wrap;
    padding: 2em 0;
    margin: 0 5%;
    flex-grow: 1; */
}

.leftPanelCards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    margin-top: 1em;
}

@media only screen and (max-width: 1280px){
    .mainHomeContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    
    #recaptcha-container{
        display: none;
    }
    
    .leftPanel{
        display: flex;
        flex-direction: column;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(15vw);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    
    .logo{
        width: 100%;
        height: 10%;
    }
    
    .mainPanel{
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
        margin-left: calc(15vw);
        width: calc(84vw);
    }
    
    .mainPanelInnerContainer{
        /* display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 2em 0;
        margin: 0 5%;
        flex-grow: 1; */
        grid-template-columns: repeat(2, 1fr);
    }
    
    .leftPanelCards{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        width: 100%;
        margin-top: 1em;
    }
}

@media only screen and (max-width: 768px){
    .logo{
        height: 80%;
        margin: 0;
        display: flex;
    }

    .mainHomeContainer{
        flex-direction: column;
    }

    .leftPanel{
        flex-direction: row;
        height: 10vh;
        width: calc(100vw);
        align-items: center;
        background-color: white;
    }

    .leftPanelCards{
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 100%;
        padding-right: 1em;
    }

    .mainPanel{
        margin-left: 0;
        margin-top: 10vh;
        height: 90vh;
        width: calc(90vw);
    }

    .mainPanelInnerContainer{
        /* height: fit-content;
        width: 100%;
        padding: 0;
        margin: 1em 0; */
        grid-template-columns: repeat(1, 1fr);
    }
}