.questionMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 90%;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.25);
    border-radius: 1em;
}

.quesNumber{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 7.5%;
    border-bottom: 0.5px dashed black;
}

.quesNumber > span{
    display: flex;
    margin-left: 1em;
    font-size: 1.5em;
}

.question{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 55%;
    align-items: center;
    margin: 0 1em;
    flex-grow: 1;
}

.ques{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80%;
    font-size: 1.25em;
    flex-wrap: wrap;
}

.options{
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.singleOption{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25%;
    width: 100%;
    font-size: 1.25em;
}

.userResponse{
    display: flex;
    flex-direction: row;
    height: 12.5%;
    width: 100%;
    border-top: 0.5px dashed black;
    justify-content: center;
    align-items: center;
}

.choice{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 20%;
}

.singleChoice{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 15%;
    font-size: 1.25em;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.25); */
    background-color: #f1f1f1;
    border: 0.5px solid gray;
    border-radius: 1em;
}

.userResponse > * {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    height: 50%;
    cursor: pointer;
    width: 10%;
    padding: 0 1em;
    margin: 0 1em;
    font-size: 1.25em;
}

.userResponse >*:active{
    box-shadow: 0 4px 8px rgba(0,0,0,0.25);
}

.clearResponse:active{
    box-shadow: none;
}

.prevQuestion{
    border: 0.5px solid gray;
    background-color: #f1f1f1;
    color: black;
    font-weight: normal;
    z-index: 4;
    width: 20%;
}

.nextQuestion{
    background-color: rgb(140, 55, 98);
    color: white;
    /* margin-left: 0; */
    font-weight: normal;
    z-index: 4;
    width: 20%;
}

.saveChoiceBtn{
    background-color: #f1f1f1;
    color: black;
    border: 0.5px dashed black;
    font-weight: normal;
    width: 20%;
}

.clearResponse{
    z-index: 4;
    margin-right: auto;
    box-shadow: none;
    margin-left: 1em;
    width: 15%;
    color: rgb(140, 55, 98);
}

@media only screen and (max-width: 768px){
    
    .questionMainContainer{
        width: 100%;
        height: 95%;
    }

    .quesNumber > span{
        font-size: 1em;
    }
    
    .ques{
        font-size: 0.8em;
        height: 80%;
    }

    .options > * {
        font-size: 0.5em;
        height: 40%;
    }

    .choice > * {
        font-size: 0.8em;
        height: 20%;
    }

    .singleChoice{
        height: 50%;
    }

    .userResponse > * {
        font-size: 0.8em;
    }

    .clearResponse{
        font-size: 0.8em;
        padding-left: 0;
    }
}