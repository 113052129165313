.login {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding: 0;
}

.loginTitle {
    font-size: 2em;
    color: rgb(140, 55, 98);
    font-weight: bolder;
    display: flex;
    justify-self: flex-start;
    margin-bottom: -160px;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    justify-content: space-between;
    width: 70%;
    max-width: 400px;
    padding: 20px;
    margin-top: 220px;
}

.phoneInputWrapper {
    display: flex;
    align-items: center;
}

.phoneInputWrapper span {
    padding-right: 10px;
    width: 50px;
}

.phoneInputWrapper input {
    flex-grow: 1;
    width: 100%;
}

.inputContainer {
    display: flex;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    margin-top: 130px;
}

.countryCode {
    padding: 5px;
    font-size: 1.25em;
}

.vertical-hr {
    border-left: 1px solid #000;
    height: 25px;
    margin: 5px 20px;
    align-items: center;
}

::placeholder {
    color: #333;
}

#userPhone{
    display: flex;
    height: 5vh;
    font-size: 1.25em;
    padding: 0 1em;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    width: 15%;
    outline: none;
    border: none;
}

.PhoneInput{
    width: calc(25vw);
    height: 5vh;
    border-radius: 0.5em;
    background-color: white;
    padding-left: 1em;
}

.PhoneInputCountry{
    width: 5em;
}

.PhoneInputCountrySelect{
    width: inherit;
    height: 5vh;
}

.PhoneInputCountrySelectArrow{
    width: 2em;
    margin-left: 0.5em;
}

.PhoneInputCountryIcon{
    margin: 0 0.5em;
    width: max-content;
    height: max-content;
    visibility: hidden;
}

.PhoneInputInput{
    display: flex;
    height: inherit;
    border-radius: 1em;
    border: none;
    outline: none;
    margin: 0 0.5em;
    font-size: 1.25em;
}

.login button{
    width: calc(20vw);
    height: 5vh;
    border: none;
    background-color: rgb(140, 55, 98);
    font-weight: bold;
    cursor: pointer;
    border-radius: 1em;
    font-size: 1.5em;
    margin-bottom: 220px;
    color: white;
}

.error{
    margin: 1em;
    color: red;
}

#otpInput{
    font-size: 1.25em;
    outline: none;
    border: none;
    border-radius: 1em;
    height: 5vh;
    padding: 0 1em;
    text-align: center;
}

#userPhone, #otpInput {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 768px){
    .loginForm{
        height: 50vh;
        width: calc(50vw);
    }

    #userPhone, #otpInput{
        width: calc(60vw);
        padding: 0.5em 1em;
        font-weight: normal;
    }

    button{
        width: calc(45vw);
        font-size: 1.5em;
        height: fit-content;
        border-radius: 1em;
        padding: 0.5em 1em;
        font-weight: normal;
    }
}
