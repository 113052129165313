.quesNumContainer{
    width: 3.5em;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.4em;
    border: 0.5px solid gray;
    border-radius: 0.5em;
    padding: 0.2em;
    flex: 0 0 4em;
    background-color: #f1f1f1;
    cursor: pointer;
}

@media only screen and (max-width: 1280px){
    .quesNumContainer{
        height: 3.25em;
        flex: 0 0 3.25em;
        font-size: 1em;
    }
}

@media only screen and (max-width: 768px){
    .quesNumContainer{
        height: 2.5em;
        flex: 0 0 2.5em;
        font-size: 0.8em;
    }
}