.quesResultMainContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 10%;
    box-sizing: border-box;
}

.questionNumber{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-left: 0.5em;
    height: 70%;
    padding: 0 0.5em;
    width: 30%;
}

.questionNumber > *{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    width: 100%;
}

.q{
    display: flex;
    justify-content: flex-start;
    height: 60%;
    font-size: 1.5em;
}

.timeTakenResult{
    display: flex;
    justify-content: flex-start;
    height: 40%;
    font-size: 1.25em;
    font-style: italic;
}

.reviewQues{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 1em;
    font-size: 1.25em;
    cursor: pointer;
    background-color: rgb(140,55,98);
    color: white;
    height: 70%;
    padding: 0 1em;
    border-radius: 1em;
    border: 0;
}

@media only screen and (max-width: 768px){
    .quesResultMainContainer{
        height: 4em;
    }

    .timeTakenResult {
        font-size: 0.6em;
    }
    
    .q {
        font-size: 1.1em;
    }

    .reviewQues{
        font-size: 1em;
    }
}