.resultMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.resultInnerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 95vh;
    width: 100%;
    background-color: #f1f1f1;
}

.resultDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 90%;
    background-color: white;
    border-radius: 1em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.resultTitle{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 8%;
    width: 100%;
    border-bottom: 0.5px dashed gray;
}

.resultTitle > span {
    display: flex;
    margin-left: 2em;
    height: 100%;
    width: fit-content;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    font-weight: bold;
}

.results{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92%;
}

.resultStats{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
    border-right: 0.5px dashed gray;
}

.resultTextual{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    height: 20%;
    width: 100%;
}

.resultTextual > *{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0 0.5em;
    width: 100%;
    height: fit-content;
    font-size: 1.25em;
}

.resultGraph{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 80%;
    width: 100%;
}

.legend{
    display: flex;
    height: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    width: max-content;
    list-style-type: none;
    padding-left: 0;
    font-weight: bold;
}

.answerStatus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
}

.answerStatus:first-child{
    color: #3DCC91;
}

.answerStatus:nth-child(2){
    color: #FF7373;
}

.answerStatus:last-child{
    color: #FFB366;
}

.percentage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin: 1em 0;
    height: 20%;
    width: 100%;
}

.percentage > * {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    width: fit-content;
    height: 100%;
}

.graphSvg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
}

.resultBreakdown{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.resultBreakdown::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .resultBreakdown {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media only screen and (max-width: 1280px){
    .resultTitle{
        font-size: 1em;
    }

    .resultTextual > * {
        font-size: 1.25em;
    }

    .results::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .results {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
}
@media only screen and (max-width: 768px){
    .resultDetails{
        width: 90%;
        height: 95%;
    }

    .resultTitle > span {
        font-size: 1em;
        margin-left: 1em;
    }

    .results{
        flex-direction: column;
        overflow: scroll;
    }

    .resultStats{
        width: 100%;
        height: 75%;
        border: none;
    }

    .resultTextual{
        height: 25%;
        font-size: 1em;
        justify-content: space-evenly;
    }

    .resultGraph{
        height: 75%;
    }

    .graphSvg{
        height: 80%;
        width: 100%;
    }

    .percentage{
        height: 20%;
        font-size: 1.1em;
        margin: 0;
    }

    .resultBreakdown{
        height: 25%;
        width: 100%;
        flex-wrap: wrap;
        overflow: visible;
    }

    .results::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .results {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
}