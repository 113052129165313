.overviewMainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100%;
}

.questions{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85%;
    width: 100%;
    padding: 0.5em 0;
}

.quesHolder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
    height: fit-content;
    padding: 0 1em;
}

.endQuiz{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    width: 100%;
}

.endQuizBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    padding: 1em;
    border-radius: 1em;
    background-color: #f1f1f1;
    cursor: pointer;
    border: 0.5px solid gray;
}

.quesHolder::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.quesHolder {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 1280px){
    .endQuizBtn{
        font-size: 0.8em;
        height: fit-content;
    }

    .quesHolder{
        flex-direction: row;
        width: 100%;
        padding: 0;
        overflow-x: scroll;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .quesHolder::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .quesHolder {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    .questions{
        height: 90%;
        padding: 1em 0 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .endQuiz{
        height: 10%;
        margin: 0.25em 0;
        font-size: 1.25em;
    }
}

@media only screen and (max-width: 768px){
    .endQuizBtn{
        font-size: 0.8em;
        height: fit-content;
    }

    .quesHolder{
        flex-direction: row;
        width: 100%;
        padding: 0;
        overflow-x: scroll;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .quesHolder::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .quesHolder {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    .questions{
        height: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .endQuiz{
        height: 50%;
        margin: 0.1em 0;
        font-size: 1em;
    }
}