.mainSpecContainer{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f1f1f1;
}

.topBar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
    width: 100%;
    height: 5vh;
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    z-index: 1;
}

.backArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    margin-right: auto;
    cursor: pointer;
    z-index: 2;
}

.specInnerContainer{
    height: 95vh;
    width: 100%;
    flex-grow: 1;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizTime{
    display: flex;
    font-size: 1.5em;
    font-weight: bold;
    height: fit-content;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.5em 0;
}

.quizDetails{
    width: calc(40vw);
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    border-radius: 2em;
    bottom: 0;
    background-color: white;
    margin: 2em 0;
}

.quizName{
    font-size: 1.5em;
    color: rgb(140, 55, 98);
    font-weight: bold;
    margin: 0.5em 0;
}

.quizQuesCount{
    font-size: 1.25em;
    font-weight: bold;
    margin: 0.5em 0;
}

.quizMarks{
    font-size: 1.25em;
    font-weight: bold;
    margin: 0.5em 0;
}

.correctAnswer{
    color: green;
    font-size: 1.2em;
}

.incorrectAnswer{
    color: red;
    font-size: 1.2em;
}

.beginQuiz{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    font-size: 1.25em;
    margin: 0.5em 0;
    padding: 0.5em 2em;
    width: fit-content;
    border-radius: 1em;
}

.back{
    font-size: 0.9em;
    margin-left: 0.5em;
}

.beginQuiz:active{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}

@media only screen and (max-width: 1280px){
    
    .quizTime{
        font-size: 1.5em;
    }

    .quizDetails{
        height: 65vh;
        width: 70%;
    }

    .quizName{
        font-size: 1.5em;
    }

    .quizMarks, .quizQuesCount{
        font-size: 1.5em;
    }

    .correctAnswer, .incorrectAnswer{
        font-size: 1.5em;
    }

    .beginQuiz{
        font-size: 1.25em;
        height: fit-content;
        padding: 1.25em;
    }
}

@media only screen and (max-width: 768px){
    .quizTime{
        font-size: 1.2em;
    }

    .quizDetails{
        height: 65vh;
        width: 85%;
    }

    .quizName{
        font-size: 1.2em;
    }

    .quizMarks, .quizQuesCount{
        font-size: 1.2em;
    }

    .correctAnswer, .incorrectAnswer{
        font-size: 1.2em;
    }

    .beginQuiz{
        font-size: 1.25em;
        height: fit-content;
        padding: 0.5em;
    }
}