.mainContainer {
  width: 100%;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
  height: 100%;
  overflow-y: scroll;
}

h1 {
  color: black;
  margin-top: 1em;
}

h3 {
  color: gray;
  margin: 0.5em 0;
}

form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly;
  width: calc(50vw);
  margin-bottom: 2em;
}

.singleInput {
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
}

.singleInput > input {
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  border: none;
  outline: none;
  font-size: 1.25em;
  padding: 0.75em 0.5em;
}

.multiInput {
  display: flex;
  width: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
}

.multiInput > input,
.multiInput > select {
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  border: none;
  outline: none;
  font-size: 1.25em;
  padding: 0.75em 0.5em;
}

#gender,
#neetYear,
#board {
  margin-right: 0.5em;
}

#dob,
#neetLang,
#class {
  margin-left: 0.5em;
}

.submitBtn {
  font-size: 1.25em;
  font-weight: normal;
  margin-top: 1em;
}
/* media queries */
@media only screen and (max-width: 1024px) {
  form {
    width: 90vw;
  }
}
@media only screen and (max-width: 630px) {
  .multiInput {
    display: flex;
    flex-direction: column;
    width: inherit;
    align-items: center;
    justify-content: space-between;
    height: 8em;
  }
  #dob,
  #neetLang,
  #class {
    margin-left: 0;
  }
  #dob {
    width: 87vw;
  }
  #gender,
  #neetYear,
  #board {
    margin-right: 0;
  }
}
