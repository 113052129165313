.popup-overlay{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    z-index: 3;
    position: fixed;
}

.popup-overlay-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em 2em 0.5em 2em;
    padding: 1.5em 0 2em 0;
    background-color: #fff;
    width: calc(45vw);
    max-height: fit-content;
    border-radius: 1.25em;
}

.popup-overlay-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 2em;
    border-radius: 20px;
}

.popupMessage{
    font-size: 1.75em;
    color: black;
    padding: 1em 0 2em 0;
}

.popupButton{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    width: 100%;
}

.popup-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: fit-content;
    width: 40%;
    padding: 0.5em 0;
    border-radius: 10px;
    border: 0.5px solid rgb(140, 55, 98);
    font-size: 1.5em;
    color: rgb(140, 55, 98);
    background-color: white;
    cursor: pointer;
    z-index: 4;   
}

.popup-btn:active{
    box-shadow: 0 0.1em 0.5em rgb(156,156,156);
}

.popup-btn-cancel{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: fit-content;
    width: 40%;
    padding: 0.5em 0;
    border-radius: 10px;
    background-color: rgb(140, 55, 98);
    font-size: 1.5em;
    color: aliceblue;
    cursor: pointer;
    border: 0;
    z-index: 4;   
}

.popup-btn-cancel:active{
    box-shadow: 0 0.1em 0.5em rgb(156,156,156);
}

@media only screen and (max-width: 1200px){
    .popup-overlay-container{
        width: 80%;
    }
    
    .popupMessage{
        font-size: 1em;
    }

    .popup-btn, .popup-btn-cancel{
        font-size: 1em;
    }
}