.quizMainContainer{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f1f1f1;
}

.timeRemaining{
    font-size: 0.7em;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-right: 1em;
    font-weight: normal;
}

.quizPageInnerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.quesContainer{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    width: calc(70vw);
    height: 100%;
    z-index: 0;
}

.overviewContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: calc(30vw);
    height: 100%;
    z-index: 0;
}
@media only screen and (max-width: 1280px){
    .timeRemaining{
        font-size: 1em;
    }
}

@media only screen and (max-width: 768px){
    .quesContainer{
        width: calc(90vw);
        height: 85%;
    }

    .overviewContainer{
        width: 100%;
        height: 15%;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
    }

    .quizPageInnerContainer{
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .timeRemaining{
        font-size: 0.8em;
    }
}