.statusContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    height: 70%;
    width: fit-content;
    padding: 0 1em;
    font-size: 1.25em;
    font-weight: bold;
    border-radius: 1em;
    border: 0;
}

@media only screen and (max-width: 768px){
    .statusContainer{
        font-size: 1em;
    }
}