.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 0;
    height: 100%;
    overflow-y: scroll;
    font-family: Montserrat, sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  h1 {
    color: rgb(140, 55, 98);
    margin-top: 1em;
    font-weight: bolder;
    font-size: xx-large;
  }
  
  h3 {
    /* color: gray; */
    margin: 0.5em 0;
    font-size: large;
  }
  
  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
    width: calc(50vw);
    margin-bottom: 2em;
    padding-left: 380px;
  }
  
  .singleInput {
    display: flex;
    width: inherit;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5em 0;
    flex-direction: column-reverse;
  }
  
  .singleInput p {
    order: 1;
    margin-top: 10px;
  }
  
  p {
    color: rgb(140, 55, 98);
    font-size: large;
    font-weight: 900;
  }
  
  .singleInput span {
    color: red;
  }
  
  .singleInput > input {
    display: flex;
    width: inherit;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    border: none;
    outline: none;
    font-size: 1.25em;
    padding: 0.75em 0.5em;
  }
  
  .singleInput > select {
    display: flex;
    width: inherit;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    border: none;
    outline: none;
    font-size: 1.25em;
    padding: 0.75em 0.5em;
  }
  
  .saveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 550px;
  }

  .edit {
    color: white;
    width: 3px;
  }

  .editButton {
    width: 100px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1200px;
  }

  .profile-info p {
    margin-left: -750px; 
    text-align: left;
  }
  
  /* Media queries */
  @media only screen and (max-width: 1024px) {
    .mainContainer {
      padding: 0 20px;
    }
  
    h1 {
      font-size: x-large;
    }
  
    form {
      width: 90vw;
      padding-left: 20px;
      padding-right: 20px;
    }

    .saveButton {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 80px;
    }
  }
  
  @media only screen and (max-width: 630px) {
    .mainContainer {
      padding: 0 10px;
    }
  
    h1 {
      font-size: large;
    }
  
    form {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    
    .singleInput {
      margin: 1em 0;
    }
  
    .submitBtn {
      font-size: 1.5em; 
      margin-top: 1em;
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: center;
    }
  
    .center-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  