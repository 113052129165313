.reviewMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.reviewInnerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 95vh;
    width: 100%;
    background-color: #f1f1f1;
}

.reviewDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 90%;
    background-color: white;
    border-radius: 1em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.reviewTitle{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 8%;
    width: 100%;
    border-bottom: 0.5px dashed gray;
}

.reviewTitle > span {
    display: flex;
    margin-left: 1em;
    height: 100%;
    width: fit-content;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    font-weight: bold;
}

.review{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92%;
}

.reviewQuestion{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80%;
    font-size: 1.25em;
    flex-wrap: wrap;
}

.optionContainer{
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.yourOption{
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5em;
    border-radius: 0.7em;
    margin: 0 0.5em;
    color: white;
}

.correctOption{
    height: 80%;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 1.5em;
    border-radius: 1em;
}

@media only screen and (max-width: 1280px){
    .optionContainer > * {
        font-size: 1em;
    }

    .reviewTitle > span{
        font-size: 1em;
    }
}

@media only screen and (max-width: 768px){
    .optionContainer > * {
        font-size: 0.8em;
    }

    .reviewTitle > span{
        font-size: 0.8em;
    }
}